/* eslint-disable require-jsdoc */
import { ParagraphNode as LexicalParagraphNode } from 'lexical';
import {
	parseStyleString,
	cssStyleDeclarationToObject,
	isElement
} from 'datatalks-utils';
import { getCachedClassNameArray } from './_lexicalUtils';

export class ParagraphNode extends LexicalParagraphNode {
	constructor(options, key) {
		super(key);
	}

	static getType() {
		return 'paragraph-node';
	}

	static clone(node) {
		return new ParagraphNode(node, node.__key);
	}

	createDOM(config) {
		const dom = document.createElement('p');
		const classNames = getCachedClassNameArray(config.theme, 'paragraph');
		if (classNames !== undefined) {
			const domClassList = dom.classList;
			domClassList.add(...classNames);
		}
		if (this.getStyle()) {
			dom.style = this.getStyle();
		}
		return dom;
	}

	exportDOM(editor) {
		const { element } = super.exportDOM(editor);

		if (isElement(element)) {
			if (this.isEmpty()) {
				element.append(document.createElement('br'));
			}

			const formatType = this.getFormatType();
			element.style.textAlign = formatType || 'left'; // Left alignment by default

			const direction = this.getDirection();
			if (direction) {
				element.dir = direction;
			}
		}

		return {
			element
		};
	}

	updateDOM(prevNode, dom, config) {
		return (
			cssStyleDeclarationToObject(dom.style) !==
			parseStyleString(prevNode.getStyle())
		);
	}

	static importJSON(serializedNode) {
		return $createParagraphNode().updateFromJSON(serializedNode);
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'paragraph-node',
			// These are included explicitly for backwards compatibility
			textFormat: this.getTextFormat(),
			textStyle: this.getTextStyle()
		};
	}
}

export function $createParagraphNode(options = {}) {
	return new ParagraphNode(options);
}

export function $isParagraphNode(node) {
	return node instanceof ParagraphNode;
}
